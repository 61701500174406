/* Pages */
    /* Home Styling */
    @media (min-width: 600px) {

        .linkStyle {
            width: 32%
        }

        .primaryDiv {
            display: flex;
            height: 100%;
        }

        .confettiSize {
            height: 100%;
            width: 30%;
        }

        .pillContainer {
            height: 100vh;  
            width: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: -3%;
        }

        .pill {
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 25px;
            padding: 2%;
            width: 100%;
            height: 10%;
            transform: scale(1);
            transition: 0.3s;
            cursor: pointer;
        }

        .pill:hover {
            transform: scale(1.05);
        }

        .pillImgSize {
            height: 100%;
            width: 100%; 
            object-fit: contain;
        }

        .contentDiv {
            width: 60%;
            margin-left: -7%;
            margin-right: auto;
            text-align: center;
        }

        .titleText {
            font-size: 4vmax;
            color: #6b0221;
        }

        .subtext {
            color: rgb(28, 28, 28);
            font-size: 2vmax;
            margin-top: -2%;
        }

        .subsubText {
            color: rgb(88, 88, 88);
            font-size: 1.5vmax;
            margin-top: -3%;
        }

        .btnDiv {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .btnSize {
            width: 100%;
            height: 6vh;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            display: flex;
            justify-content: space-between;
            align-items: center;
            transform: scale(1);
            transition: 0.3s;
        }

        .btnSize:hover {
            transform: scale(1.05);
            cursor: pointer;
        }

        .btnImgSize {
            height: 70%;
            width: 20%; 
            object-fit: contain;
        }

        .btnSize h1 {
            width: 80%;
            color: #6B0221;
            font-size: 1.6vmin;
        }

        .redText {
            color: #6B0221;  
        }


        .uniRowSize {
            width: 95%;
            height: 8vh;
            display: flex;
            justify-content: space-between;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 15px;
            margin-left: auto;
            margin-right: auto;
            padding: 1%;
            transform: scale(1);
            transition: 0.3s;
            margin-bottom: 1%;
        }

        .uniRowSize:hover {
            transform: scale(1.05);
            cursor: pointer;
        }

        .uniRowImgSize {
            width: 20%;
            height: 100%;
            object-fit: contain;
        }

        .uniTextDiv {
            display: flex;
            justify-content: space-between;
            width: 80%;
            height: 100%;
            align-content: center;
            text-align: left;
        }

        .uniTextDiv h1 {
            color: gray;
            font-weight: bold;
            font-size: 3vmin;
            width: 100%;
            margin-top: auto;
            margin-bottom: auto;
        }

        .uniTextDiv p {
            color: #6B0221;
            text-align: left;
            font-weight: 200;
            font-size: 3.5vmin;
        }

        .uniRatingDiv {
            display: flex;
        }

        .starSize {
            /* position: relative; */
            width: auto;
            height: 40%;
            /* margin-bottom: 15%; */
        }

        .starFlex {
            display: flex;
            align-items: center;
            height: 100%;
            margin-right: 5%;
        }
    }

    @media (max-width: 599px) {

        .starFlex {
            margin-top: -1%;
            display: flex;
            justify-content: center;
            align-content: center;
        }
        
        .primaryDiv {
            display: flex;
            height: 100%;
        }

        .confettiSize {
            display: none;
        }

        .pillContainer {
            display: none;
        }

        .pill {
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 25px;
            padding: 2%;
            width: 100%;
            height: 10%;
            transform: scale(1);
            transition: 0.3s;
            cursor: pointer;
        }

        .pill:hover {
            transform: scale(1.05);
        }

        .pillImgSize {
            height: 100%;
            width: 100%; 
            object-fit: contain;
        }

        .contentDiv {
            width: 100%;
            margin-right: auto;
            text-align: center;
        }

        .titleText {
            font-size: 6vmax;
            margin-top: 0%;
            color: #6b0221;
        }

        .subtext {
            color: rgb(28, 28, 28);
            font-size: 3vmax;
            margin-top: -4%;
        }

        .subsubText {
            color: rgb(88, 88, 88);
            font-size: 2vmax;
            margin-top: -3%;
        }

        .btnDiv {
            width: 100%;
        }

        .btnSize {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2%;
            height: 6vh;
            border-radius: 10px;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            display: flex;
            justify-content: space-between;
            align-items: center;
            transform: scale(1);
            transition: 0.3s;
        }

        .btnImgSize {
            height: 70%;
            width: 20%; 
            object-fit: contain;
        }

        .btnSize h1 {
            width: 80%;
            color: #6B0221;
            font-size: 4vmin;
        }

        .redText {
            color: #6B0221;  
            font-size: 3vmax;
        }


        .uniRowSize {
            width: 90%;
            height: 8vh;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 15px;
            margin-left: auto;
            margin-right: auto;
            padding: 1%;
            transform: scale(1);
            transition: 0.3s;
            margin-bottom: 2%;
        }

        .uniRowImgSize {
            display: none;
        }

        .uniTextDiv h1 {
            color: gray;
            margin-top: -1%;
            margin-left: 0%;
            font-weight: 500;
            font-size: 5vmin;
            text-align: center;
        }

        .uniTextDiv p {
            color: #6B0221;
            font-weight: 600;
            font-size: 3vmax;
            margin-top: 0%;
        }

        .uniRatingDiv {
            display: flex;
        }

        .starSize {
            /* position: relative; */
            width: 25px;
            height: 25px;
            margin-top: 0%;
            margin-left: 2%;
        }
    }

    /* User Styling */
        /* Login Styling */
        @media (min-width: 600px) {

            .bodyDiv {
                position: fixed; /* Make the overlay fixed on top of the screen */
                top: 0;
                left: 0;
                margin: 0;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1000; /* High z-index to ensure it's above other content */
            }
        
            .loginContainer {
                width: 50%; 
                /* max-width: 600px;  */
                height: auto; 
                max-height: 80vh;
                background-color: white;
                border-radius: 25px;
                padding: 20px; 
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        
            .loginContainer h1 {
                color: #6b0221;
                margin-top: 0%;
                text-align: center;
                font-size: 3vmin;
            }
        
            .loginContainer h2 {
                color: #6b0221;
                font-size: 3vmin;
                margin-top: 0%;
            }
        
            .crossDiv {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                position: relative;
                width: 100%;
            }
        
            .crossBtn {
                width: 10%;
                transform: scale(1);
                transition: 0.25s;
            }
        
            .crossBtn:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        
            .logo {
                width: 30%;
                height: 10vh;
                margin-top: -8%;
                object-fit: contain;
            }
        
            .logoSmall {
                height: 100%;
                transform: scale(1);
                transition: 0.25s;
            }
        
            .signInLogo {
                height: 100%;
                transform: scale(1);
                transition: 0.25s;
            }
        
            .signInLogo:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        
            .emailDiv {
                display: flex;
                align-items: center;
                width: 100%;
                height: 25%;
                margin-top: 2%;
                margin-bottom: 2%;
            }
        
            .emailEntryInput {
                background-color: white;
                width: 100%;
                height: 6vh;
                border-radius: 10px;
                box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.25);
                border: none;
                font-size: 2vmax;
                font-family: Arial, Helvetica, sans-serif;
                padding-left: 2%;
                font-weight: bold;
                color: gray;
                margin-top: 2%;
            }
        
            .signInWith {
                display: flex;
                width: 80%;
                height: 8vh;
                justify-content: space-evenly;
                margin-top: -5%;
            }
        
            .loginForm {
                width: 80%;
                margin: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        
            .loginForm h2 {
                text-align: center;
                margin-bottom: -2%;
                margin-top: 0%;
            }
        
            .submitButton {
                background-color: white;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                color: #6b0221;
                font-weight: bolder;
                font-size: 2vmax;
                cursor: pointer;
                transform: scale(1);
                transition: 0.5s;
                width: 50%;
                height: 6vh;
            }
        
            .submitButtonInverted {
                background-color: #6b0221;
                color: white;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                font-weight: bolder;
                font-size: 2vmax;
                cursor: pointer;
                transform: scale(1);
                transition: 0.5s;
                width: 50%;
                max-height: 50px;
                height: 7vh;
                margin-top: 2%;
            }
        
            .submitButton:hover {
                transform: scale(1.1);
            }
        }
        
        @media (max-width: 599px) {
            .bodyDiv {
                position: fixed; /* Make the overlay fixed on top of the screen */
                top: 0;
                left: 0;
                margin: 0;
                width: 100vw;
                height: 100vh;
                background-color: white; /* Change to semi-transparent for consistency */
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1000; /* High z-index to ensure it's above other content */
            }
        
            .loginContainer {
                width: 100%; /* Adjust as needed */
                height: 100%; /* Let the height adjust based on content */
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: white;
            }
        
            .loginContainer h1 {
                color: #6b0221;
                margin-top: 0%;
                font-size: 4vmax;
                text-align: center;
            }
        
            .loginContainer h2 {
                color: #6b0221;
                font-size: 3vmax;
                margin-top: 0%;
            }
        
            .crossDiv {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                position: relative;
                width: 100%;
            }
        
            .crossBtn {
                width: 10%;
                transform: scale(1);
                transition: 0.25s;
            }
        
            .crossBtn:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        
            .logo {
                width: 20%;
                height: 10vh;
                object-fit: contain;
            }
        
            .logoSmall {
                height: 100%;
                transform: scale(1);
                transition: 0.25s;
            }
        
            .signInLogo {
                height: 100%;
                transform: scale(1);
                transition: 0.25s;
            }
        
            .signInLogo:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        
            .emailDiv {
                display: flex;
                align-items: center;
                width: 100%;
            }
        
            .emailEntryInput {
                background-color: white;
                width: 100%;
                height: 5vh;
                border-radius: 10px;
                box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.25);
                border: none;
                font-size: 2vmax;
                font-family: Arial, Helvetica, sans-serif;
                padding-left: 2%;
                font-weight: bold;
                color: gray;
                margin-top: 2%;
            }
        
            .signInWith {
                display: flex;
                width: 80%;
                height: 12vh;
                justify-content: space-evenly;
            }
        
            .submitButton {
                background-color: white;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                color: #6b0221;
                font-weight: bolder;
                font-size: 3vmax;
                cursor: pointer;
                transform: scale(1);
                transition: 0.5s;
                width: 50%;
                max-height: 50px;
                height: 6vh;
            }
        
            .submitButtonInverted {
                background-color: #6b0221;
                color: white;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                font-weight: bolder;
                font-size: 2vmax;
                cursor: pointer;
                transform: scale(1);
                transition: 0.5s;
                width: 50%;
                max-height: 50px;
                height: 7vh;
                margin-top: 5%;
            }
        
            .submitButton:hover {
                transform: scale(1.1);
            }
        
            .loginForm {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        
            .loginForm h2 {
                text-align: center;
                margin-bottom: 0%;
            }
        }

        /* Profile Stats Styling */
        @media (min-width: 600px) {
            .editProfileButton {
                height: 70%;
                width: 150px;
                border-radius: 30px;
                background-color: white;
                color: gray;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
                font-size: 2.5vmin;
                font-weight: bold;
                display: inline-block;
                margin-left: 10px;
                cursor: pointer;
                transition: 0.3s;
            }
        
            .editProfileButton:hover {
                transform: scale(1.1);
                transition: 0.3s;
            }
        
            .profileStatsPage {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Arial', sans-serif;
                box-sizing: border-box;
                width: 100%;
            }
            
            .profilePhoto {
                width: 150px;
                height: 150px;
                position: relative;
                overflow: hidden;
                border-radius: 50%;
            }
            
            .profileStatsBubble {
                border-radius: 25px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                width: 100%;
            }
            
            .statsCrossDiv {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                width: 100%;
                height: 8vh;
            }
            
            .statsCrossBtn {
                height: 100%;
                /* width: 10%; */
                transform: scale(1);
                transition: 0.25s;
            }
            
            .statsCrossBtn:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
            
            .username {
                color: #6b0221;
            }
            
            .institution {
                color: #6B0221;
                opacity: 40%;
            }
            
            .stats {
                font-size: 150%;
                color: #6f6d6d;
                margin-top: -5%;
            }
            
            .navButtons {
                position: absolute;
                top: 0;
                right: 0;
                padding-right: 1%;
                padding-top: 1%;
                display: flex;
                justify-content: space-between;
            }
            
            .exitButton:active,
            .editProfileButton:active {
                transition: 0s;
                color: #6b0221;
                background-color: white;
            }
            
            .submitButtonLogout {
                background-color: white;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                color: #6b0221;
                font-weight: bolder;
                font-size: 2vmax;
                cursor: pointer;
                transform: scale(1);
                transition: 0.5s;
                width: 50%;
                height: 6vh;
                margin-left: auto;
                margin-right: auto;
                margin-top: 5%;
            }
            
            .submitButtonLogout p {
                color: #6B0221;
            }
            
            .submitButtonLogout:hover {
                transform: scale(1.1);
            }
        }
        
        @media (max-width: 599px) {
            .editProfileButton {
                height: 70%;
                width: 30vw;
                border-radius: 30px;
                background-color: white;
                color: gray;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
                font-size: 4vmin;
                font-weight: bold;
                display: inline-block;
                margin-left: 10px;
                cursor: pointer;
                transition: 0.3s;
            }
        
            .profileStatsPage {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Arial', sans-serif;
                box-sizing: border-box;
                width: 100%;
            }
            
            .profilePhoto {
                width: 150px;
                height: 150px;
                position: relative;
                overflow: hidden;
                border-radius: 50%;
            }
            
            .profileStatsBubble {
                border-radius: 25px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                width: 100%;
            }
            
            .statsCrossDiv {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                width: 100%;
                height: 8vh;
            }
            
            .statsCrossBtn {
                height: 100%;
                /* width: 10%; */
                transform: scale(1);
                transition: 0.25s;
            }
            
            .statsCrossBtn:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
            
            .username {
                color: #6b0221;
            }
            
            .institution {
                color: #6B0221;
                opacity: 40%;
            }
            
            .stats {
                font-size: 150%;
                color: #6f6d6d;
                margin-top: -5%;
            }
            
            .navButtons {
                position: absolute;
                top: 0;
                right: 0;
                padding-right: 1%;
                padding-top: 1%;
                display: flex;
                justify-content: space-between;
            }
            
            .exitButton:active,
            .editProfileButton:active {
                transition: 0s;
                color: #6b0221;
                background-color: white;
            }
            
            .submitButtonLogout {
                background-color: white;
                border: none;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                color: #6b0221;
                font-weight: bolder;
                font-size: 2vmax;
                cursor: pointer;
                transform: scale(1);
                transition: 0.5s;
                width: 50%;
                height: 6vh;
                margin-left: auto;
                margin-right: auto;
                margin-top: 5%;
            }
            
            .submitButtonLogout p {
                color: #6B0221;
            }
            
            .submitButtonLogout:hover {
                transform: scale(1.1);
            }
        }

        /* Edit Profile Styling */
        @media (min-width: 0px) {

            .editProfilePage {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Arial', sans-serif;
                box-sizing: border-box;
                width: 100%;
            }
        
            /* .profilePhoto {
                margin-top: 2%;
                margin-bottom: 2%;
                width: 33%;
                height: auto;
                border-radius: 50%;
            } */
        
            .editProfileBubble {
                position: relative;
                width: 100%;
                min-width: fit-content;
                border-radius: 25px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                
                /* border-radius: 25px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                width: 100%; */
            }
        
            .navButtons {
                position: absolute;
                top: 0;
                right: 0;
                padding-right: 1%;
                padding-top: 1%;
                display: flex;
                justify-content: space-between;
            }
        
            .exitButton,
            .cancelButton,
            .updateButton {
                border-radius: 15px;
                background-color: white;
                color: #6b0221;
                border: none;
                box-shadow: 0 0 1px 1px #6f6d6d;
                font-size: 115%;
                display: inline-block;
                margin-left: 10px;
                cursor: pointer;
            }
        
            .exitButton:hover,
            .cancelButton:hover,
            .updateButton:hover {
                transform: scale(1.1);
                transition: 0.3s;
                color: white;
                background-color: #6b0221;
            }
        
            .exitButton:active,
            .cancelButton:active,
            .updateButton:active {
                transition: 0s;
                color: #6b0221;
                background-color: white;
            }
        
            .updateButton {
                font-size: 175%;
                font-weight: bold;
                margin-top: 5%;
                padding-top: 2%;
                padding-bottom: 2%;
                width: 33%;
                border-radius: 10px;
                color: white;
                background-color: #6b0221;
            }
        
            .updateButton:hover {
                color: white;
                background-color: #6b0221;
            }
        
            .updateButton:active {
                transition: 0s;
                color: #6b0221;
                background-color: white;
            }
        
            .displayNameInput,
            .studentStatusInput,
            .emailInput,
            .universityInput {
                width: 100%;
                margin: 2%;
                border-radius: 10px;
                border: none;
                box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.25);
                background-color: white;
                padding: 3% 1.5%;
                font-size: 115%;
            }
        
            .studentStatusInput {
                /*Removing default browser drop downs since it doesn't apply styling with CSS*/
                padding: 3% 1.5%;           /* Adjust the values as needed */
                -webkit-appearance: none;   /* Removes default style in Chrome and Safari */
                -moz-appearance: none;      /* Removes default style in Firefox */
                appearance: none;           /* Removes default style for other browsers */
                width: 102.5%;              /* Manually tuned this value to let the size of the drop down fit the size of the other input fields*/
        
                /* Removing the default drop down appearances removed the icon that shows it's a drop down, following code adds it back*/
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" viewBox="0 0 32 32"><path d="M16 22L6 9h20l-10 13z"/></svg>');
                background-repeat: no-repeat;
                background-position: right .7em top 50%, 0 0;   /* Position the background image */
                background-size: .99em auto, 100%;              /* Resize the background image */
        
                /* Code is provided by Bing Copilot */
            }
        
            .inputQuadrant {
                width: 95%;
            }
        
            .displayNameTitle,
            .studentStatusTitle,
            .emailTitle,
            .universityTitle {
                margin-left: 2.5%;
                text-align: left;
                color: #6b0221;
                font-weight: bold;
                font-size: 150%
            }
        }
        
        @media(min-width: 600px) {
                .inputs {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
        }
        
        @media(max-width: 599px) {
                .inputs {
                    display: block;
                }
        }

    /* Programs Styling */
    @media (min-width: 0px) {
        .selected {
            background-color: #6b0221;
            color: white;
        }
        .allUnis.selected {
            background-color: #6b0221 !important;
            color: white !important;
        }
    }

    @media (min-width: 600px) {

        .programsTitle,
        .fieldsTitle,
        .levelTitle,
        .universitiesTitle,
        .resultsTitle {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            color: #6b0221;
        }

        .programsPage {
            padding-bottom: 1%;
        }

        .programsTitle, .subjectsTitle {
            text-align: left;
            margin-left: 7%;
            font-size: 4vmax;
        }

        .fieldsTitle,
        .levelTitle,
        .resultsTitle, .universitiesTitleProgram {
            text-align: left;
            font-size: 3vmax;
            margin-left: 2%;
            margin-bottom: 0%;
        }

        .fieldsBubble,
        .levelBubble,
        .universitiesBubble,
        .resultsBubble {
            position: relative;
            width: 90%;
            padding-left: 1%;
            padding-right: 1%;
            padding-bottom: 1.5%;
            border-radius: 25px;
            margin-left: auto;
            margin-right: auto;
        }

        .fields,
        .universities,
        .filterButtons,
        .uniResults1,
        .uniResults2,
        .uniResults3 {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        .levels {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        .uni1TitleBubble,
        .uni2TitleBubble,
        .uni3TitleBubble {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 0;
            margin: 0;
        }

        .uni1Title,
        .uni2Title,
        .uni3Title {
            color: #6b0221;
            /* background-color: #6b0221; */
            margin-top: 1%;
            margin-bottom: 1%;
            position: relative;
            text-align: center;
            font-size: 225%;
            padding-top: 1.5%;
            padding-bottom: 1.5%;
            font-weight: bold;
            transform: scale(0.97)
        }

        .filterButtons {
            grid-template-columns: repeat(2, 1fr);
            font-size: 125%;
        }

        .undergradButton,
        .postgradButton {
            transform: scale(0.94)
        }

        .undergradButton:hover,
        .postgradButton:hover {
            transform: scale(1)
        }

        .undergradButton:active,
        .postgradButton:active {
            transform: scale(1)
        }

        .sortButton,
        .orderButton {
            font-size: 125%;
            padding: 3%;
            color: rgb(68, 68, 68);
            background-color: white;
            transform: scale(0.94)
        }

        .sortButton:hover,
        .orderButton:hover {
            transform: scale(1);
        }

        .sortButton:active,
        .orderButton:active {
            color: white;
            transform: scale(1)
        }

        bub {
            transform: scale(0.9);
            transition: 0.3s;
            margin-top: 1%;
            margin-bottom: 1%;
            position: relative;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none;
            color: rgb(68, 68, 68);
            background-color: white;
            text-align: center;
            font-size: 3vmin;
            padding: 5%;
            font-weight: bold;
        }

        bub:hover {
            transform: scale(0.95);
            transition: 0.3s;
            cursor: pointer;
        }

        bub:active {
            transform: scale(0.95);
            transition: 0s;
            color: white;
            background-color: #6b0221;
        }

        .universities .allUnis {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            font-size: 4vw;
            transform: scale(0.9);
            transition: 0.3s;
            color: #6b0221;
            background-color: white;
        }

        .universities .allUnis:hover {
            cursor: pointer;
            transform: scale(0.95);
            transition: 0.3s;
        }

        .universities .allUnis:hover:active {
            transition: 0.0s;
            /* color: #6b0221; */
            background-color: white;
        }

        .universities .allUnis .allUnisTitle :hover {
            transform: scale(0.95);
            /* Need to fix the text also scaling along side with the scaling of the button, resizing the window makes the text look funny */
        }

        .universities {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 35px;
            grid-template-columns: repeat(4, 1fr);
        }

        .universities img {
            width: 100%;
            height: auto;
            cursor: pointer;
        }

        .universities button {
            transform: scale(0.90);
            transition: 0.3s;
            border-radius: 15px;
            padding: 15px;
            background-color: #ffffff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none
        }

        .universities button:hover {
            transform: scale(0.95);
            transition: 0.3s;
        }

        .shuffle {
            margin-left: 2%;
        }

        /*TODO: Make the buttons top left*/
        /*TODO: Make the buttons stay clicked when you click them*/
    }

    @media (max-width: 599px) {
        .programsTitle,
        .fieldsTitle,
        .levelTitle,
        .universitiesTitleProgram,
        .resultsTitle {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            color: #6b0221;
        }

        .programsPage {
            padding-bottom: 1%;
        }

        .programsTitle, .subjectsTitle {
            text-align: left;
            margin-left: 7%;
            font-size: 6vmax;
        }

        .fieldsTitle,
        .levelTitle,
        .resultsTitle,
        .universitiesTitleProgram {
            text-align: left;
            font-size: 4vmax;
            margin-left: 2%;
            margin-bottom: 0%;
        }

        .fieldsBubble,
        .levelBubble,
        .universitiesBubble,
        .resultsBubble {
            position: relative;
            width: 90%;
            padding-left: 1%;
            padding-right: 1%;
            padding-bottom: 1.5%;
            border-radius: 25px;
            margin-left: auto;
            margin-right: auto;
        }

        .fields,
        .universities,
        .filterButtons,
        .uniResults1,
        .uniResults2,
        .uniResults3 {
            display: flex;
            flex-direction: column;
        }

        .fields bub {
            display: flex;
            height: 2vh;
            justify-content: center;
            align-items: center;
        }

        .levels {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }

        .uni1TitleBubble,
        .uni2TitleBubble,
        .uni3TitleBubble {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 0;
            margin: 0;
        }

        .uni1Title,
        .uni2Title,
        .uni3Title {
            color: #6b0221;
            margin-top: 1%;
            margin-bottom: 1%;
            position: relative;
            text-align: center;
            font-size: 225%;
            padding-top: 1.5%;
            padding-bottom: 1.5%;
            font-weight: bold;
            transform: scale(0.97)
        }

        .filterButtons {
            grid-template-columns: repeat(2, 1fr);
            font-size: 125%;
        }

        .undergradButton,
        .postgradButton {
            transform: scale(0.94)
        }

        .undergradButton:active,
        .postgradButton:active {
            transform: scale(1)
        }

        .sortButton,
        .orderButton {
            font-size: 125%;
            padding: 3%;
            color: rgb(68, 68, 68);
            background-color: white;
            transform: scale(0.94)
        }

        .sortButton:active,
        .orderButton:active {
            color: white;
            transform: scale(1)
        }

        bub {
            transform: scale(0.9);
            transition: 0.3s;
            margin-top: 1%;
            margin-bottom: 1%;
            position: relative;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none;
            color: rgb(68, 68, 68);
            background-color: white;
            text-align: center;
            font-size: 5vmin;
            padding: 5%;
            font-weight: bold;
        }

        bub:active {
            transform: scale(0.95);
            transition: 0s;
            color: white;
            background-color: #6b0221;
        }

        .universities .allUnis {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            font-size: 4vw;
            transform: scale(0.9);
            transition: 0.3s;
            color: #6b0221;
            background-color: white;
        }

        .universities {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 35px;
            display: flex;
            flex-direction: column;
        }

        .universities img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
        }

        .universities button {
            width: 100%;
            height: 10vh;
            transform: scale(0.9);
            transition: 0.3s;
            border-radius: 15px;
            padding: 3%;
            background-color: #ffffff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none
        }
    }

    /* Subjects Styling */
    @media  (min-width: 0px) {
        .subjectsTitle,
        .fieldsTitle,
        .levelTitle,
        .universitiesTitleProgram,
        .resultsTitle {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            color: #6b0221;
        }

        .subjectsPage {
            padding-bottom: 1%;
        }

        .fieldsBubble,
        .levelBubble,
        .universitiesBubble,
        .resultsBubble {
            position: relative;
            width: 90%;
            padding-left: 1%;
            padding-right: 1%;
            padding-bottom: 1.5%;
            border-radius: 25px;
            margin-left: auto;
            margin-right: auto;
        }

        .levelBubble {
            position: relative;
            width: 90%;
            padding-left: 1%;
            padding-right: 1%;
            padding-bottom: 1.5%;
            border-radius: 25px;
            margin-left: auto;
            margin-right: auto;
        }

        .universities .allUnis {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            font-size: 4vw;
            transform: scale(0.9);
            transition: 0.3s;
            color: #6b0221;
            background-color: white;
        }

        .universities .allUnis:hover:active {
            transition: 0.0s;
            color: #6b0221;
            background-color: white;
        }

        .universities .allUnis .allUnisTitle :hover {
            transform: scale(0.95);
        }
    }

    /* Universities Styling */
    @media (max-width: 599px) {
        .logoButtons {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .universitiesPage {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            color: #6b0221;
            margin: 0;
            overflow: hidden;
        }

        .universitiesTitle {
            text-align: left;
            margin-left: 7%;
            font-size: 6vmax;
        }

        .popularUniversitiesTitle {
            text-align: left;
            font-size: 4vmax;
            margin-left: 2%;
            margin-bottom: 0%;
        }

        .popularUniversitiesBubble {
            position: relative;
            width: 90%;
            padding-left: 1%;
            padding-right: 1%;
            padding-bottom: 1%;
            border-radius: 35px;
            margin-left: auto;
            margin-right: auto;
        }

        .logoButtons img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
        }

        .logoButtons button {
            width: 100%;
            height: 10vh;
            transform: scale(0.9);
            transition: 0.3s;
            border-radius: 15px;
            padding: 3%;
            background-color: #ffffff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none
        }

        /* .logoButtons button:hover {
            transform: scale(0.95);
        } */

        .searchUniversitiesTitle {
            margin-top: 4%;
            position: relative;
            font-size: 4vmax;
            font-weight: bold;
            text-align: left;
            margin-left: 2%;
        }

        .searchBar {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1% auto 0.25%;
        }

        .searchUniversitiesText {
            position: relative;
            width: 95%;
            border-radius: 15px;
            box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.25);
            border: none;
            text-align: left;
            padding-left: 1%;
            font-size: 150%;
            font-weight: bold;
            /* flex-grow: 1; */
            height: 5vh;
        }

        .searchButton {
            transform: scale(1);
            transition: 0.3s;
            background-color: #6b0221;
            color: white;
            border: none;
            font-weight: bold;
            border-radius: 15px;
            font-size: 150%;
            height: 5vh;
            width: 95%;
            margin-top: 3%;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        }

        .resultsTitle {
            position: relative;
            text-align: left;
            font-size: 3vmax;
            margin-left: 2%;
            font-weight: bold;
        }

        .resultsBubble {
            position: relative;
            width: 90%;
            padding: 1%;
            border-radius: 35px;
            margin: 1% auto 3%;
        }

        .resultsList {
            display: block;
            margin: 0 auto;
            list-style-type: none;
            align-items: center;
            padding: 0;
            width: 99%;
        }

        li {
            transform: scale(0.975);
            transition: 0.3s;
            margin-top: 1%;
            margin-bottom: 1%;
            position: relative;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none;
            color: gray;
            background-color: white;
            text-align: left;
            font-size: 200%;
            padding: 1% 0.25% 1% 1%;
            font-weight: bold;
            height: 100%;
            text-decoration: none;
        }

        li:hover:active {
            transition: 0.0s;
            color: #6b0221;
            background-color: white;
        }

        .uni1Tick,
        .uni2Tick,
        .uni3Tick,
        .uni4Tick,
        .uni5Tick,
        .uni6Tick,
        .uni7Tick {
            display: none;
        }
    }

    @media (min-width: 600px) {

        .universitiesPage {
            font-family: 'Arial', sans-serif;
            box-sizing: border-box;
            color: #6b0221;
            margin: 0;
            overflow: hidden;
        }

        .universitiesTitle {
            text-align: left;
            margin-left: 7%;
            font-size: 4vmax;
        }

        .popularUniversitiesTitle {
            text-align: left;
            font-size: 3vmax;
            margin-left: 2%;
            margin-bottom: 0%;
        }

        .popularUniversitiesBubble {
            position: relative;
            width: 90%;
            padding-left: 1%;
            padding-right: 1%;
            padding-bottom: 1%;
            border-radius: 35px;
            margin-left: auto;
            margin-right: auto;
        }

        .logoButtons {
            position: relative;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        .logoButtons img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
        }

        .logoButtons button {
            width: 30vw;
            height: 15vh;
            transform: scale(0.9);
            transition: 0.3s;
            border-radius: 15px;
            padding: 3%;
            background-color: #ffffff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none
        }

        .logoButtons button:hover {
            transform: scale(0.95);
        }

        .searchUniversitiesTitle {
            margin-top: 4%;
            position: relative;
            font-size: 3vmax;
            font-weight: bold;
            text-align: left;
            margin-left: 2%;
        }

        .searchBar {
            display: flex;
            align-items: center;
            margin: 1% auto 0.25%;
        }

        .searchUniversitiesText {
            position: relative;
            width: 90%;
            border-radius: 15px;
            margin-left: 2%;
            box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.25);
            border: none;
            text-align: left;
            padding-left: 1%;
            font-size: 150%;
            font-weight: bold;
            /* flex-grow: 1; */
            height: 5vh;
        }

        .searchButton {
            transform: scale(1);
            transition: 0.3s;
            background-color: #6b0221;
            color: white;
            border: none;
            font-weight: bold;
            border-radius: 15px;
            font-size: 150%;
            height: 5vh;
            margin-left: 1%;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        }

        .searchButton:hover {
            transform: scale(1.1);
            transition: 0.3s;
            cursor: pointer;
        }

        .searchButton:hover:active {
            transition: 0s;
            background-color: white;
            color: #6b0221;
            border: none;
        }

        .resultsTitle {
            position: relative;
            text-align: left;
            font-size: 3vmax;
            margin-left: 2%;
            font-weight: bold;
        }

        .resultsBubble {
            position: relative;
            width: 90%;
            padding: 1%;
            border-radius: 35px;
            margin: 1% auto 3%;
        }

        .resultsList {
            display: block;
            margin: 0 auto;
            list-style-type: none;
            align-items: center;
            padding: 0;
            width: 99%;
        }

        li {
            transform: scale(0.975);
            transition: 0.3s;
            margin-top: 1%;
            margin-bottom: 1%;
            position: relative;
            border-radius: 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border: none;
            color: gray;
            background-color: white;
            text-align: left;
            font-size: 200%;
            padding: 1% 0.25% 1% 1%;
            font-weight: bold;
            height: 100%;
            text-decoration: none;
        }

        li u {
            text-decoration: none;
        }

        li:hover {
            transform: scale(1);
            transition: 0.3s;
            cursor: pointer;
        }

        li:hover:active {
            transition: 0.0s;
            background-color: white;
        }
    }

    /* DetailView Styling */
    @media (min-width: 600px) {
        .chevFlex {
            display: flex;
            align-items: center;
        }
        
        .imgStyle {
            height: 40px;
            margin-right: 1%;
            cursor: pointer;
        }
        
        .gradLev {
            color: gray;
            font-size: 3vmin;
            margin-top: -2%;
        }
        
        .courseNameDetail {
            color: rgb(107, 107, 107);
            font-size: 4vmin;
        }
        
        .paragraphDetail {
            color: black;
            font-weight: normal;
            font-size: 2vmin;
        }

        .uniTitleDetail {
            color: #6b0221;
        }
    }

    @media (max-width: 599px) {
        .chevFlex {
            display: flex;
            align-items: center;
        }
        
        .imgStyle {
            height: 40px;
            margin-right: 1%;
            cursor: pointer;
        }
        
        .gradLev {
            color: gray;
            font-size: 5vmin;
            margin-top: -4%;
        }
        
        .courseNameDetail {
            color: rgb(107, 107, 107);
            font-size: 6vmin;
            margin-top: 0%;
        }
        
        .paragraphDetail {
            color: black;
            font-weight: normal;
            font-size: 4vmin;
        }

        .uniTitleDetail {
            color: #6b0221;
            font-size: 5vmin;
        }
    }

    /* UniReview Styling */
    @media(min-width: 0px) {

        .App {
            text-align: center;
        }

        .App-logo {
            height: 40vmin;
            pointer-events: none;
        }

        @media (prefers-reduced-motion: no-preference) {
            .App-logo {
                animation: App-logo-spin infinite 20s linear;
            }
        }

        .App-header {
            background-color: #282c34;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: calc(10px + 2vmin);
            color: white;
        }

        .App-link {
            color: #61dafb;
        }

        @keyframes App-logo-spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }


        /* My Stuff */
        .noDesktop {
            display: none;
        }

        /* Text Stylings */

        p {
            color: white;
            font-family: 'Arial', sans-serif;
            font-weight: bold;
            font-size: 1.5vw;
        }

        h3 {
            color: white;
            font-family: 'Arial', sans-serif;
            font-weight: bold;
            font-size: 2vw;
        }
    }
    /* University Title and Logo Styles */
    @media (min-width: 600px) {

        .backgroundImageStyle {
            width: 100vw;
            display: block;
            height: 50vh;
            object-fit: cover;
        }

        .universityHeadingText {
            font-weight: bold;
            font-size: 3vmax;
            font-family: 'Arial', sans-serif;
            margin-left: 2%;
            margin-top: 0%;
        }

        .universityLogo {
            height: 80%;
            margin-right: 2%;
            align-self: center;
        }

        .titleAndTextContainer {
            height: 10vh;
            width: 100vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .titleAndTextSub {
            height: 10vh;
            width: 80%;
        }

        .suburbText {
            color: rgb(68, 68, 68);
            margin-left: 2%;
            margin-top: -2%;
        }

        .topDownGradientPosition {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 70%;
        }

        .topDownGradient {
            background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .bottomUpGradient {
            background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .uniTitleAndImgAbsolute {
            position: 'absolute';
            top: 0;
            left: 0;
            width: '100%';
            height: '15%';
        }
    }

    @media (max-width: 599px) {

        .titleAndTextSub {
            height: 10vh;
            width: 100%;
        }

        .backgroundImageStyle {
            width: 100vw;
            display: block;
            height: 30vh;
            object-fit: cover;
        }

        .universityHeadingText {
            font-weight: bold;
            font-size: 5.5vw;
            font-family: 'Arial', sans-serif;
            margin-left: 2%;
            align-self: center;
            margin-top: 0%;
        }

        .universityLogo {
            display: none;
        }

        .titleAndTextContainer {
            height: 100%;
            display: flex;
        }

        .suburbText {
            color: rgb(68, 68, 68);
            font-size: 3vw;
            margin-left: 2%;
            margin-top: -4%;
        }

        .topDownGradientPosition {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 90%;
        }

        .topDownGradient {
            background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .bottomUpGradient {
            background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
        }

        .uniTitleAndImgAbsolute {
            position: 'absolute';
            top: 0;
            left: 0;
            width: '100%';
            height: '15%';
        }
    }

    /* Highest rated courses and accom Styles */
    @media (min-width: 600px) {

        .programAndSubjectMainHeight {
            height: 30%;
        }

        .programAndSubjectHeight {
            height: 100%;
            z-index: 0;
        }

        .programAndSubjectContainer {
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            margin: auto;
        }

        .programAndSubjectButtonContainer {
            display: flex;
            justify-content: center; /* horizontally center */
            align-items: center; /* vertically center */
            height: 100%;
            width: 100%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 20px;
            transform: scale(1);
            transition: 0.3s;
        }

        .linkStyle {
            height: 50%;
            width: 30%;
            text-decoration: none;
    }

        .programAndSubjectButtonContainer:hover {
            transform: scale(1.1);
            cursor: pointer;
        }

        .programAndSubjectText {
            color: gray;
            font-size: 2vmin;
            text-align: center;
        }

        .programAndSubjectRating {
            color: #943250;
            margin-right: 1%;
        }

        .programAndSubjectRatingDiv {
            display: flex;
            height: 50%;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 3%;
        }

        .programAndSubjectImg {
            height: 60%;
            margin-right: 1%;
        }

        .sectionTitle {
            color: #6b0221;
            margin-left: 5%;
            margin-bottom: -2%;
        }

        .pushPadding {
            margin-top: 0%;
        }
    }

    @media (max-width: 599px) {

        .programAndSubjectHeight {
            width: 100%;
            height: 100%;
            z-index: 0;
            text-align: center;
        }

        .programAndSubjectContainer {
            height: 50%;
            width: 90%;
            margin: auto;
        }

        .programAndSubjectButtonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            margin: auto;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            transform: scale(1);
            transition: 0.3s;
            margin-bottom: 3%;
        }

        .linkStyle {
            max-height: 50px;
            width: 90%;
            text-decoration: none;
        }

        .programAndSubjectText {
            color: gray;
            font-size: 5vw;
            text-align: center;
        }

        .programAndSubjectRating {
            color: #943250;
            font-size: 4vmin;
            margin-right: 1%;
        }

        .programAndSubjectRatingDiv {
            display: flex;
            height: 50%;
            align-items: center;
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 1%;
        }

        .programAndSubjectImg {
            height: 60%;
            margin-right: 1%;
        }

        .sectionTitle {
            color: #6b0221;
            font-size: 7vw;
        }

        .pushPadding {
            margin-top: 5%;
        }
    }

/* Components */
    /* AddPostPopup Styling */
    @media (min-width: 600px) {

        .bodyDiv {
            position: fixed; /* Make the overlay fixed on top of the screen */
            top: 0;
            left: 0;
            margin: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000; /* High z-index to ensure it's above other content */
        }
    
        .addPostContainer {
            width: 70%;
            height: auto;
            background-color: white;
            border-radius: 25px;
            padding: 20px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .addPostContainer h1 {
            color: #6b0221;
            margin-top: 0%;
            text-align: center;
            font-size: 4vmin;
        }
    
        .addPostContainer h2 {
            color: #6b0221;
            font-size: 2vw;
            margin-top: 0%;
        }
    
        .crossDiv {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
            width: 100%;
        }
    
        .crossBtn {
            width: 10%;
            transform: scale(1);
            transition: 0.25s;
        }
    
        .crossBtn:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    
        .postForm {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .postTypeSelect {
            width: 200px;
            height: 40px;
            color: black;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding-left: 10px;
            font-size: 16px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin-top: 2%;
        }
        /* Adding a custom arrow using Unicode */
        .postTypeSelect {
            background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><polygon points="6,9 0,0 12,0" style="fill:%23cccccc"/></svg>');
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px 12px;
        }
    
        .postForm h2 {
            text-align: center;
            margin-bottom: 0%;
        }

        .postForm .postReviewRating {
            display: block;
            margin: 1% auto auto auto;
        }

        .postTextInput {
            width: 100%; /* Adjust width as needed */
            height: 200px; /* Adjust height as needed */
            resize: none; /* Allows vertical resizing */
            padding: 2%;
            border: 1px solid #ccc;
            border-radius: 20px;
            vertical-align: top;
            text-align: left;
            font-family: 'Arial', sans-serif;
        }
    }
    
    @media (max-width: 599px) {
    
        .bodyDiv {
            position: fixed; /* Make the overlay fixed on top of the screen */
            top: 0;
            left: 0;
            margin: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000; /* High z-index to ensure it's above other content */
        }
    
        .addPostContainer {
            width: 100%;
            height: 100%;
            background-color: white;
            border-radius: 25px;
            padding: 20px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .addPostContainer h1 {
            color: #6b0221;
            margin-top: 0%;
            text-align: center;
            font-size: 4vmin;
        }
    
        .addPostContainer h2 {
            color: #6b0221;
            font-size: 2vw;
            margin-top: 0%;
        }
    
        .crossDiv {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            position: relative;
            width: 100%;
        }
    
        .crossBtn {
            width: 10%;
            transform: scale(1);
            transition: 0.25s;
        }
    
        .crossBtn:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    
        .postForm {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .postTypeSelect {
            width: 50%;
            height: 5vh;
            color: black;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding-left: 10px;
            font-size: 16px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        /* Adding a custom arrow using Unicode */
        .postTypeSelect {
            background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><polygon points="6,9 0,0 12,0" style="fill:%23cccccc"/></svg>');
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px 12px;
            margin-top: 2%;
            margin-bottom: 2%;
        }
    
        .postForm h2 {
            text-align: center;
            margin-bottom: 0%;
            font-size: 4vmin;
        }
    
        .postTextInput {
            width: 100%; /* Adjust width as needed */
            height: 20vh; /* Adjust height as needed */
            resize: none; /* Allows vertical resizing */
            padding: 2%;
            border: 1px solid #ccc;
            border-radius: 20px;
            vertical-align: top;
            text-align: left;
            font-family: 'Arial', sans-serif;
        }
    }

    /* InteractionArea Styling */
    @media (max-width: 599px) {

        .sectionTitle2 {
            margin-left: 40%;
            color: #6b0221;
            font-size: 7vw;
        }

        .redLine {
            height: 2px;
            background-color: #6b0221;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 7%;
        }

        .noData p {
            color: gray;
            text-align: center;
            font-size: 3vmax;
        }

        .mobDropDown {
            margin-top: 10px;
        }

        .mainActionArea {
            margin-top: 3%;
            min-height: 300px;
            height: auto;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            border-radius: 20px;
            padding: 10px;
        }

        .actionBarStyle {
            display: none;
        }

        .actionButtonContainerStyle {
            max-height: 75px;
            width: 25%;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            align-items: center;
            transform: scale(1);
            transition: 0.3s;
            background-color: white;
        }

        .actionButtonContainerStyle h3 {
            color: #6b0221;
        }

        .selectedOutline {
            background-color: #6b0221;
            outline-offset: -8px;
        }

        .selectedOutline h3 {
            color: white;
        }

        .actionButtonContainerStyle:hover {
            cursor: pointer;
            transform: scale(1.1);
        }

        .actionButtonImageStyle {
            margin-left: 2%;
            max-height: 50%;
            align-self: center;
        }

        .interactionPadding {
            margin-top: 2%;
        }

        /* Create Button */
        .createButtonBackground {
            /* display: none; */
            background-color: white;
            display: flex;
            justify-content: space-between;
            border-radius: 100px;
            width: 80vw;
            max-height: 30px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            padding: 2%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .createButtonImage {
            /* height: 50%; */
            margin-right: 2%;
            width: 8%;
            align-self: center;
        }

        .createButtonText {
            align-self: center;
            color: #6b0221;
            font-size: 6vmin;
            margin-left: 2%;
        }
    }

    @media (min-width: 600px) {

        .interactionPadding {
            margin-top: 8%;
        }

        .sectionTitle2 {
            display: none;
        }

        .redLine {
            height: 2px;
            background-color: #6b0221;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
        }

        .noData p {
            color: gray;
            text-align: center;
            font-size: 3vmax;
        }

        .justifyInteractionContent {
            display: flex; /* Enables flexbox layout mode */
            flex-direction: column; /* Stacks flex items vertically */
            justify-content: space-evenly; /* Evenly distributes space between items */
            align-items: center; /* Aligns items horizontally at the center */
        }

        .mobDropDown {
            display: none;
        }

        .mainActionArea {
            margin-top: 3%;
            height: auto;
            width: 90%;
            /* background-color: lightgray;  */
            margin-left: auto;
            margin-right: auto;
            border-radius: 20px;
            padding-bottom: 4%;
        }

        .actionBarStyle {
            padding-top: 2%;
            display: flex;
            justify-content: space-around;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            height: 8%;
            width: 100%;
        }

        .actionButtonContainerStyle {
            max-height: 75px;
            width: 25%;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            align-items: center;
            transform: scale(1);
            transition: 0.3s;
            background-color: white;
        }

        .actionButtonContainerStyle h3 {
            color: #6b0221;
        }

        .selectedOutline {
            background-color: #6b0221;
            outline-offset: -8px;
        }

        .selectedOutline h3 {
            color: white;
        }

        .actionButtonContainerStyle:hover {
            cursor: pointer;
            transform: scale(1.1);
        }

        .actionButtonImageStyle {
            margin-left: 2%;
            max-height: 50%;
            align-self: center;
        }

        /* Create Button */
        .createButtonBackground {
            background-color: white;
            display: flex;
            justify-content: space-evenly;
            border-radius: 100px;
            width: 18%;
            max-height: 50px;
            margin-top: 1%;
            float: right;
            margin-right: 4%;
            transform: scale(1);
            transition: 0.3s;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        .createButtonBackground:hover {
            cursor: pointer;
            transform: scale(1.1);
        }

        .createButtonImage {
            /* height: 50%; */
            width: 10%;
            align-self: center;
        }

        .createButtonText {
            align-self: center;
            color: #6b0221;
            font-size: 1.8vw;
        }
    }

    /* MenuBar Styling */
    @media (min-width: 0px) {
        p {
            color: white;
            font-family: 'Arial', sans-serif;
            font-weight: bold;
            font-size: 3vmin;
        }
        
        h3 {
            color: white;
            font-family: 'Arial', sans-serif;
            font-weight: bold;
            font-size: 2vw;
        }
        
        /* End Text Stylings */
        
        /* Header Styles for Desktop Blog */
        .headerBackground {
            background-color: #6b0221;
            height: 100%;
        }
        
        .headerContentAlignment {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 4%;
            height: 100%;
        }
        
        .headerImageAlignment {
            max-width: 100%;
            max-height: 100%;
        }
        
        .searchDivAlignment {
            height: 70%;
            width: 20%;
            background-color: #6b0221;
            display: flex;
            padding-left: 1%;
            padding-right: 1%;
            border-radius: 10px;
            box-shadow: inset 0 0 20px 0 rgba(0, 0, 0, 0.5);
        }
        
        .searchInputStyling {
            width: 100%;
            border: none;
            background: none;
            outline: none;
            box-shadow: none;
            color: white;
            font-size: 2.5vmin;
            font-weight: bold;
        }
        
        .searchImageAlignment {
            height: 40%;
            align-self: center;
        }
        
        .headerContentAlignment p {
            transform: scale(1);
            transition: 0.3s;
        }
        
        .headerContentAlignment p:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
        
        .loginBorder {
            background-color: white;
            padding-left: 1%;
            padding-right: 1%;
            padding-top: 0.5%;
            padding-bottom: 0.5%;
            border-radius: 10px;
            color: #6b0221;
        }
        
        /* End Header Styles for Desktop Blog */
        
        /* Header Styles for Mobile Blog */
        
        .mobileLargeHeader {
            height: 100%;
        }
        
        .headerBackgroundMob {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #6b0221;
        }
        
        .headerImageAlignment {
            max-width: 100%;
            max-height: 100%;
        }
        
        .menuBarAlignment {
            height: 50%;
            display: flex;
            align-items: center;
            margin-right: 4%;
        }
        
        .menuBarImage {
            height: 100%;
        }
        
        .searchBarImage {
            height: 150%;
            margin-right: 15%;
        }
        
        .mobTextStart {
            background-color: #6b0221;
            height: 0%;
            padding-left: 1%;
            margin-top: -7%;
            transition: 0.5s ease;
        }
        
        .mobTextEnd {
            background-color: #6b0221;
            height: 600%;
            padding-left: 1%;
            margin-top: -7%;
            transition: 0.5s ease;
        }
        
        .links {
            transition: opacity 0.5s ease;
            opacity: 0; /* Start with links invisible */
            margin-top: 7%;
            height: 100%;
            text-decoration: none;
            pointer-events: none;
        }
        
        .links.visible {
            opacity: 1; /* Make links fully visible */
            pointer-events: auto;
        }
        
        .noLinks {
            display: none;
        }
        
        .links p {
            font-size: 4.75vh;
            margin-top: -5%;
            margin-right: 2%;
            text-align: end;
        }
        
        
        .searchResultsContainer {
            position: absolute;
            top: 5%;
            left: 60%;
            width: 23%;
            height: 30%;
            z-index: 1;
        }
        
        .searchResultsOverlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        
        .searchResults {
            position: relative;
            z-index: 1000;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 10px;
            overflow-y: auto;
            max-height: 200px;
        }
        
        .searchResults ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        
        .searchResults li {
            padding: 8px;
            border-bottom: 1px solid #eee;
        }
        
        .searchResults li:last-child {
            border-bottom: none;
        }
        
    }

    /* ReviewCard Styling */
    @media (min-width: 600px) {

        .desc {
            color: gray;
            font-weight: 100;
            font-size: 16px;
            margin-right: 2%;
            margin-top: 1%;
            margin-bottom: 0%;
        }
    
        .mainCard {
            width: 80vw;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 25px;
            padding-left: 2%;
            padding-bottom: 0.5%;
        }
    
        .topicDiv {
            margin-top: 1%;
            display: flex;
            align-items: center;
        }
    
        .infoDiv {
            width: 100%;
            margin-left: 2%;
            margin-right: 2%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    
        .infoDiv h1 {
            color: rgb(59, 59, 59);
        }
    
        .infoDiv h2 {
            color: rgb(113, 113, 113);
            font-weight: lighter;
        }
    
        .profileIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 75px;
            height: 75px;
            border-radius: 100%;
            background-color: black;
        }
    
        .profileIcon h3 {
            font-size: 5vmin;
        }
    
        .iconMainDiv {
            display: flex;
            justify-content: space-between;
            width: 20%;
        }
    
        .iconDiv {
            display: flex;
            /* justify-content: space-between; */
            width: 50%;
        }
    
        .iconDiv p {
            color: gray;
            margin-left: 8%;
        }
    
        .icons {
            width: 30px;
            object-fit: contain;
        }
    
        .cardButtonStyling {
            all: unset;
            width: 50%;
        }
    
        .sepLine {
            height: 1px;
            background-color: #6e6e6e41;
            width: 100%;
            margin-left: -1%;
            margin-top: 1%;
        }

        .reviewProfile {
            width: 80px;
            height: 80px;
            clip-path: circle(50% at 50% 50%);
            object-fit: cover;
        }

        .reviewRating {
            color: #943250;
            margin-right: 1%;
        }

        .reviewRatingDiv {
            display: flex;
            height: 50%;
            align-items: center;
            margin-left: 3%;
        }

        .reviewImg {
            height: 50px;
            margin-right: 1%;
        }
    }
    
    @media (max-width: 599px) {
    
        .sepLine {
            height: 1px;
            background-color: #6e6e6e41;
            width: 100%;
            margin-left: -1%;
            margin-top: 0%;
        }
    
        .desc {
            color: gray;
            font-weight: 100;
            font-size: 4vmin;
            margin-right: 2%;
            margin-top: 1%;
            margin-bottom: 0%;
        }
    
        .mainCard {
            width: 80vw;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2%;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 25px;
            padding-left: 2%;
            padding-bottom: 0.5%;
        }
    
        .topicDiv {
            display: flex;
            align-items: center;
        }
    
        .infoDiv {
            width: 100%;
            margin-left: 2%;
            margin-right: 2%;
            margin-top: 2%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    
        .infoDiv h1 {
            color: rgb(59, 59, 59);
            font-size: 5vmin;
        }
    
        .infoDiv h2 {
            color: rgb(113, 113, 113);
            font-weight: lighter;
            font-size: 4vmin;
        }
    
        .profileIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            background-color: black;
        }
    
        .profileIcon h3 {
            font-size: 5vmin;
        }
    
        .iconMainDiv {
            display: flex;
            justify-content: space-between;
            width: 30%;
        }
    
        .iconDiv {
            display: flex;
            /* justify-content: space-between; */
            width: 50%;
        }
    
        .iconDiv p {
            color: gray;
            margin-left: 8%;
        }
    
        .icons {
            width: 30px;
            object-fit: contain;
        }
    
        .cardButtonStyling {
            all: unset;
            width: 50%;
        }

        .reviewProfile {
            width: 60px;
            height: 60px;
            clip-path: circle(50% at 50% 50%);
            object-fit: cover;
        }

        .reviewRating {
            color: #943250;
            margin-right: 1%;
            font-size: 30px;
        }

        .reviewRatingDiv {
            display: flex;
            height: 50%;
            align-items: center;
            margin-left: 3%;
        }

        .reviewImg {
            width: 7vw;
            margin-right: 1%;
        }
    }